import React from 'react'
import Button from '../../components/Button'



export default function SecondReesult(){
    return (
        <div className='container-xl'>
            <div className='row mr-0'>
                <div className='col'>
                    <h1 className="text-center">CEFALOSPORINAS DE PRIMEIRA GERAÇÃO</h1>

                    <p className="text-center mb-5">(prescrição de acordo com o recomendado pela Comissão de Controle de Infecção Hospitalar – CCIH - do hospital em que se realiza a cirurgia)</p>


                    <h5>CEFALOTINA </h5>
                    <p><strong>Administração endovenosa:</strong> 2 gramas de 30 a 60 minutos antes da cirurgia </p>
                    <p><strong>Durante a cirurgia:</strong> repetir dose (2 gramas) em 4 horas se cirurgia prolongada (acima de 4 horas) </p>
                    <p><strong>Depois da cirurgia:</strong> 1 a 2 gramas de 6/6 horas por 24 horas</p>

                    <h5>CEFAZOLINA</h5>
                    <p><strong>Administração endovenosa ou intramuscular:</strong> 2 gramas de 30 a 60 minutos antes da cirurgia </p>
                    <p><strong>Durante a cirurgia:</strong> repetir dose (1 grama) em 4 horas se cirurgia prolongada (acima de 4 horas) </p>
                    <p><strong>Depois da cirurgia:</strong> 1 grama de 8/8horas por 24 horas</p>

                    <h2 className="text-center m-5">ALTERNATIVAS</h2>

                    <h5>AMOXICILINA + ACIDO CLAVULÂNICO  </h5>
                    <p><strong>Administração endovenosa:</strong> 1 grama de amoxicilina + 200 miligramas de ácido clavulânico de 30 a 60 minutos antes da cirurgia </p>
                    <p><strong>Durante a cirurgia:</strong> repetir dose (1 grama de amoxicilina + 200 miligramas de ácido clavulânico) em 4 horas se cirurgia prolongada (acima de 4 horas)</p>
                    <p><strong>Depois da cirurgia:</strong> 1 grama de amoxicilina + 200 miligramas de ácido clavulânico de 8/8horas por 24 horas</p>

                    <h5>CEFUROXIMA</h5>
                    <p><strong>Administração endovenosa:</strong> 1,5 grama de 30 a 60 minutos antes da cirurgia </p>
                    <p><strong>Durante a cirurgia:</strong> repetir dose (1,5 grama) em 4 horas se cirurgia prolongada (acima de 4horas) </p>
                    <p><strong>Depois da cirurgia:</strong> 750 miligramas de 8/8h por 24 horas (quando indicado)</p>

                    <Button link="/">Sair</Button>
                </div>
            </div>
        </div>
    )
}