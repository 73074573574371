import React from 'react'
import style from './style.module.css'

import mama from '../../assets/mama.png'

export default function Header(){
    return (
        <header className={style.header}>
            <div className='container-sm'>
                <img className="img-fluid" src={mama} />
            </div>
        </header>
    )
}