import React from 'react'

import Header from './components/Header'
import Footer from './components/Footer'
import Home from './pages/Home'
import IntroPage from './pages/IntroPage'

import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom'
import AlgorithmPage from './pages/AlgorithmPage'
import SecondReesult from './pages/SecondResult'
import FirstResult from './pages/FirstResult'

function App() {
  return (
    <Router>
      <div className='container-fluid m-0 p-0'>
        <Header/>
        <div className='content-container p-3'>
          <Routes>
            <Route
              path='/'
              exact
              element={<Home/>}
            />
            <Route
              path='/intro'
              exact
              element={<IntroPage/>}
            />
            <Route 
              path='/algorithm'
              exact
              element={<AlgorithmPage />}
            />
            <Route 
              path='/secondResult'
              exact
              element={<SecondReesult />}
            />
            <Route 
              path='/firstResult'
              exact
              element={<FirstResult />}
            />
          </Routes>
        </div>
        <Footer/>
      </div>
    </Router>
  )
}

export default App
