import React from 'react'
import Button from '../../components/Button'

export default function IntroPage(){
    return(
        <div>
            <div className='row'>
                <div className='col'>
                    <p>
                        Olá!
                    </p>
                    <p>
                        A infecção pós-operatória em Cirurgia Plástica é uma ocorrência importante que, além de comprometer a saúde e a integridade física do paciente, aumenta os custos e pode comprometer os resultados do procedimento. Isso frequentemente leva o cirurgião plástico a prescrever antibióticos além do que seria necessário, visando prevenir esta complicação.
                    </p>
                    <p>
                        O MamaATB ® traz informação confiável e de fácil acesso para auxiliar o cirurgião plástico a prescrever a antibioticoprofilaxia adequada para diferentes tipos de cirurgia mamaria não reconstrutiva.
                    </p>
                    <p>
                        No MamaATB ® , os fatores de risco inerentes ao ato cirúrgico e as individualidades de cada paciente, que sabidamente podem influenciar os riscos de infecção, são levados em consideração na sugestão do esquema de antibioticoprofilaxia indicado para cada caso. Portanto, tem o potencial de diminuir a prescrição excessiva de antibióticos, diminuindo os riscos de resistência bacteriana e custos desnecessários.
                    </p>
                    <h5 className='m-2 mt-4 mb-4'>
                        Quais são as cirurgias abordadas no aplicativo?
                    </h5>
                    <p>
                        <strong>1- Mamoplastia não reconstrutiva com uso de implantes:</strong>
                    </p>
                    <p>
                        A- Mamoplastia de aumento
                    </p>
                    <p>
                        B- Mastopexia com prótese
                    </p>
                    <p>
                        <strong>2- Mamoplastia não reconstrutiva sem uso de implantes:</strong>
                    </p>
                    <p>
                        A- Mamoplastia redutora
                    </p>
                    <p>
                        B- Mastopexia
                    </p>
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <Button link='/algorithm'>Avançar</Button>
                </div>
            </div>
        </div>
    )
}