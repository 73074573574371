import React from 'react'

import mpcas from '../../assets/MPCAS.png'
import Button from '../../components/Button'

export default function Home(){
    return (
        <div className='container-sm'>
            <div className='row mr-0'>
                <div className='col m-3 p-5 text-center'>
                    <p>Karine Helena Bócoli</p>
                    <p>Daniela Francescato Veiga</p>
                    <p>Edgard da Silva Garcia</p>
                    <p>Lyliana Coutinho Resende Barbosa</p>
                    <img className='img-fluid' src={mpcas} alt='Mestrado Profissional em Ciências Aplicadas à Saúde' />
                    <div className='mt-3'>
                        <Button link='/intro'>
                            Iniciar
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}