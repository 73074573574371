import React from 'react'
import style from './style.module.css'

import {
    Link
} from 'react-router-dom'

export default function Button({ children, link, onClick }){
    if(link){
        return (
            <div className='text-center'>
                <Link to={link}>
                    <button className={'btn ' + style.button}>
                        { children }
                    </button>
                </Link>
            </div>
        )
    }else{
        return (
            <div className='text-center'>
                <button className={'btn ' + style.button} onClick={onClick}>
                    { children }
                </button>
            </div>
        )
    }
}