import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import Button from '../../components/Button'

const steps = {
    'yes': {
        'A': {
            'description': 'Tempo Cirúrgico',
            0: 'Até 170 minutos',
            1: '170 a 210 minutos',
            2: 'Mais que 210 minutos'
        },
        'B': {
            'description': 'Índice de massa corporal',
            0: 'Até 29,9 Kg/m²',
            1: '30 Kg/m² ou mais',
        },
        'C': {
            'description': 'Idade',
            0: 'Até 59 anos e 11 meses',
            1: '60 anos ou mais',
        },
        'D': {
            'description': 'Tabagismo nas últimas quatro semanas',
            0: 'Não',
            1: 'Sim',
        },
        'E': {
            'description': 'Índice Glicêmico',
            0: 'Até 125 mg/dl',
            1: 'De 126 a 199 mg/dl',
            2: '200 mg/dl ou mais',
        },
        'F': {
            'description': 'Peso mamário ressecado',
            0: 'Até 499 g',
            1: '500 a 999 g',
            2: 'Mais de 1000 g'
        },
    },
    'no': {
        'A': {
            'description': 'Tempo Cirúrgico',
            0: 'Até 170 minutos',
            1: '170 a 210 minutos',
            2: 'Mais que 210 minutos'
        },
        'B': {
            'description': 'Índice de massa corporal',
            0: 'Até 29,9 Kg/m²',
            1: '30 Kg/m² ou mais',
        },
        'C': {
            'description': 'Idade',
            0: 'Até 59 anos e 11 meses',
            1: '60 anos ou mais',
        },
        'D': {
            'description': 'Tabagismo nas últimas quatro semanas',
            0: 'Não',
            1: 'Sim',
        },
        'E': {
            'description': 'Índice Glicêmico',
            0: 'Até 125 mg/dl',
            1: 'De 126 a 199 mg/dl',
            2: '200 mg/dl ou mais',
        },
        'F': {
            'description': 'Via de acesso utilizada',
            0: 'Sulco Inframamário',
            1: 'Axilar',
            2: 'Periareolar'
        },
        'G': {
            'description': 'Peso mamário ressecado',
            0: 'Até 249 g',
            1: '250 a 999 g',
            2: 'Mais de 1000 g'
        },
    }
}

export default function AlgorithmPage(){
    const [data, setData] = useState({ step: 0 })
    const [answer, setAnswer] = useState({})
    const navigate = useNavigate()

    const handleYes = () => {
        setData({
            ...data,
            step: 1,
            implant: 'yes'
        })
    }
    const handleNo = () => {
        setData({
            ...data,
            step: 1,
            implant: 'no'
        })
    }

    useEffect(() => {
        setAnswer({})
    }, [data])

    const next = () => {
        let hasRed = false
        let points = 0

        Object.keys(answer).forEach(item => {
            if(answer[item] === 2){
                hasRed = true
            }

            points += answer[item]
        })

        if(points >= 3 || hasRed){
            navigate('/secondResult')
        }else{
            navigate('/firstResult')
        }
    }

    const handleRadio = (event) => {
        const { value, name } = event.target

        let oldAnswer = answer

        oldAnswer[name] = parseInt(value)
        
        setAnswer(oldAnswer)
    }

    if(data.step === 0){
        return (
            <>
                <h1 className='text-center'>Cirurgia mamária não reconstrutiva</h1>
                <h3 className='text-center'>Faz uso de implante mamário?</h3>

                <Button onClick={handleYes}>Sim</Button>
                <Button onClick={handleNo}>Não</Button>
            </>
        )
    }else{
        const thisSteps = steps[data.implant]

        const keys = Object.keys(thisSteps)

        return (
            <>
                <h1 className='text-center mb-4'>Cirurgia mamária não reconstrutiva</h1>
                <h4 className='text-center mb-5'>Responda as questões abaixo</h4>
                {
                    keys.map((key, index) => (
                        <div className='mb-3' key={index}>
                            <h6>{key} - {thisSteps[key].description}</h6>
                            {
                                Object.keys(thisSteps[key]).map((val, index) => {
                                    if(val !== 'description'){
                                        return(
                                            <div className='mb-1' key={val}>
                                                <input 
                                                    name={key}
                                                    type='radio' 
                                                    key={index} 
                                                    value={val} 
                                                    onChange={handleRadio}
                                                /> { thisSteps[key][val] } <br/>
                                            </div>
                                        )
                                    }else{
                                        return null
                                    }
                                })
                            }
                        </div>
                    ))
                }
                <Button onClick={next}>Avançar</Button>
            </>
        )
    }
    
}